import { useNavigate } from 'react-router-dom';
import { usePilotContext } from '../pilotContext';
import { BigSwitch } from './BigSwitch';

function ServiceSwitch() {
    const { selectedService, setSelectedService } = usePilotContext();
    const navigate = useNavigate();


    return (
        <div>
            <BigSwitch
                checked={selectedService === 5}
                onChange={(_, checked) => { navigate("/"); setSelectedService(checked ? 5 : 4) }}
                inputProps={{ "aria-label": "controlled" }}
            />
            <div className="inline-block">Service {selectedService}</div>
        </div>
    );
};

export default ServiceSwitch;