import { useCallback, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import {
  colors,
  Divider,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
} from "@mui/material";
//import { Tab, Tabs } from "@mui/base";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import LiveViewTab from "./LiveViewTab";
// import PatternsTab from "./PatternsTab";
// import InsightsTab from "./InsightsTab";
import { Metric, TimeAggregationPeriod, Units, MetricDescriptions } from "../../Common/Enums";
import { RoomSensor } from "../../Common/Types";
import PatternsTab from "./PatternsTab";
import { Time } from "highcharts";
import { debug } from "console";
import { Insights } from "@mui/icons-material";
import InsightsTab from "./InsightsTab";
import axiosInstance from "../../axiosInstance";
import axios from "axios";
import { usePilotContext } from "../../pilotContext";

ChartJS.register({ annotationPlugin });

type RoomPageState = {
  selectedMetric: Metric;
  selectedSensorId: number;
  roomSensors: RoomSensor[];
  tabId: string;
  dateFrom: Date;
  dateTo: Date;
  timeAggregation: TimeAggregationPeriod;
  metricDescriptions: MetricDescriptions;
  roomName: string;
}


export default function RoomPage() {
  const navigate = useNavigate();

  const dateNow = new Date();

  const { roomId } = useParams();
  const [state, setState] = useState<RoomPageState>({
    selectedMetric: Metric.None,
    selectedSensorId: -1,
    roomSensors: [],
    tabId: "liveview",
    dateFrom: new Date(new Date().setUTCFullYear(dateNow.getUTCFullYear() - 1)),
    dateTo: dateNow,
    timeAggregation: TimeAggregationPeriod.Month,
    metricDescriptions: {} as MetricDescriptions,
    roomName: ""
  });
  const [excludeAnomalies, setExcludeAnomalies] = useState(true);

  const { selectedPilotInfo } = usePilotContext();

  const handleMetricSelected = useCallback((metric: Metric) => {
    setState(s => ({ ...s, selectedMetric: metric, selectedSensorId: -1 }))
  }, []);

  const handleSensorSelected = useCallback((sensorId: number) => {
    setState(s => ({ ...s, selectedSensorId: sensorId }))
  }, []);

  const handleDateFromChange = useCallback((dateFrom: Date) => {
    setState(s => ({ ...s, ...adjustDatesBasedOnTabAndTimeAggregation(dateFrom, s.dateTo, s.timeAggregation, s.tabId, true) }))
  }, []);

  const handleDateToChange = useCallback((dateTo: Date) => {
    setState(s => ({ ...s, ...adjustDatesBasedOnTabAndTimeAggregation(s.dateFrom, dateTo, s.timeAggregation, s.tabId, false) }))
  }, []);

  const handleTimeAggregationChange = useCallback((timeAggregation: TimeAggregationPeriod) => {
    setState(s => ({ ...s, timeAggregation: timeAggregation, ...adjustDatesBasedOnTabAndTimeAggregation(s.dateFrom, s.dateTo, timeAggregation, s.tabId, false) }))
  }, []);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source(); // Create a cancel token source

    axiosInstance
      .get(`/api/metric_descriptions`, {
        cancelToken: cancelTokenSource.token, // Pass the cancel token
      })
      .then((response) => {
        const metricDescriptions: MetricDescriptions = response.data;

        setState((s) => ({
          ...s,
          metricDescriptions,
        }));
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          console.log(err.message);
        }
      });

    // Cleanup function to cancel the axios request
    return () => {
      cancelTokenSource.cancel("Operation canceled by the user."); // Cancel the axios request if the component unmounts
    };
  }, []);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source(); // Create a cancel token source

    axiosInstance
      .get(`/api/rooms/${roomId}/sensors`, {
        cancelToken: cancelTokenSource.token, // Pass the cancel token
      })
      .then((response) => {
        const data: { sensor_id: number; sensor_name: string; metric_id: Metric; units: Units }[] = response.data;

        const sensors: RoomSensor[] = data.map((sensor) => ({
          id: sensor.sensor_id,
          name: sensor.sensor_name,
          metric: sensor.metric_id,
          units: sensor.units,
        }));

        const metric = sensors
          .toSorted((a, b) => a.metric - b.metric)[0]?.metric ?? Metric.None;

        setState((s) => ({
          ...s,
          roomSensors: sensors,
          selectedMetric: metric,
          selectedSensorId: -1,
        }));
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          console.log(err.message);
        }
      });

    // Cleanup function to cancel the axios request
    return () => {
      cancelTokenSource.cancel("Operation canceled by the user."); // Cancel the axios request if the component unmounts
    };
  }, [roomId]);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source(); // Create a cancel token source

    axiosInstance
      .get(`/api/rooms/${roomId}/room_name`, {
        cancelToken: cancelTokenSource.token, // Pass the cancel token
      })
      .then((response) => {
        const data: string = response.data;
        setState((s) => ({
          ...s,
          roomName: data
        }));
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          console.log(err.message);
        }
      });

    // Cleanup function to cancel the axios request
    return () => {
      cancelTokenSource.cancel("Operation canceled by the user."); // Cancel the axios request if the component unmounts
    };
  }, [roomId]);

  const metricSensors = state.roomSensors.filter(x => x.metric === state.selectedMetric)

  return (
    <div className="absolute p-7 w-full min-w-[1000px] overflow-x-auto">
      <div className="flex justify-between">
        <div className="content-center">
          <Link to="/"><div className="font-bold inline">IEQ Web Service​ </div></Link>
          <div className="inline">{selectedPilotInfo.city}, {selectedPilotInfo.name}, {state.roomName}</div>
        </div>
        <div>
          <Tabs
            value={state.tabId}
            onChange={(_, newTabId) => setState(s => ({ ...s, tabId: newTabId, ...adjustDatesBasedOnTabAndTimeAggregation(s.dateFrom, s.dateTo, s.timeAggregation, newTabId, false) }))}
            TabIndicatorProps={{ style: { display: "none" } }}
            sx={{
              ".MuiTabs-flexContainer": {
                //    justifyContent: "space-between",
              },
              ".MuiTab-root": {
                textTransform: "none",
                fontWeight: "bold",
                fontSize: "100%",
                marginX: "3px",
                paddingX: "40px",
                paddingBottom: "5px",
                backgroundColor: "#cbe4f4",
                color: "#233641",
                width: "180px",
              },
              ".Mui-selected": {
                backgroundColor: `#86c9f1`,
              },
              ".MuiTab-home": {
                backgroundColor: `#dddddd`,
                color: `#000000`
              },
            }}
          >
            <Tab label="Home" value="home" className="MuiTab-home" onClick={() => navigate("/")}></Tab>
            <Tab label="Live view" value="liveview"></Tab>
            <Tab label="Patterns" value="patterns"></Tab>
            <Tab label="Insights" value="insights"></Tab>
          </Tabs>
        </div>
      </div>
      <Divider
        style={{
          borderBottomWidth: 2,
          borderBottomColor: colors.grey[800],
        }}
      />
      {(() => {
        switch (state.tabId) {
          case "liveview":
            return <LiveViewTab
              roomId={roomId}
              selectedMetric={state.selectedMetric}
              selectedSensorId={state.selectedSensorId}
              metricSensors={metricSensors}
              roomSensors={state.roomSensors}
              excludeAnomalies={excludeAnomalies}
              metricSelected={handleMetricSelected}
              sensorSelected={handleSensorSelected}
              setExcludeAnomalies={setExcludeAnomalies}
              metricDescriptions={state.metricDescriptions}
            />
          case "patterns":
            return <PatternsTab
              roomId={roomId}
              selectedMetric={state.selectedMetric}
              selectedSensorId={state.selectedSensorId}
              metricSensors={metricSensors}
              roomSensors={state.roomSensors}
              excludeAnomalies={excludeAnomalies}
              dateFrom={state.dateFrom}
              dateTo={state.dateTo}
              timeAggregation={state.timeAggregation}
              metricSelected={handleMetricSelected}
              sensorSelected={handleSensorSelected}
              setExcludeAnomalies={setExcludeAnomalies}
              setDateFrom={handleDateFromChange}
              setDateTo={handleDateToChange}
              setTimeAggregationPeriod={handleTimeAggregationChange}
              metricDescriptions={state.metricDescriptions}
            />
          case "insights":
            return <InsightsTab
              roomId={roomId}
              dateFrom={state.dateFrom}
              dateTo={state.dateTo}
            />
          default:
            break;
        }
      })()}
    </div>
  );
}

function adjustDatesBasedOnTabAndTimeAggregation(dateFrom: Date, dateTo: Date, timeAggregation: TimeAggregationPeriod, tabId: string, dateFromWasChanged: boolean): { dateFrom: Date, dateTo: Date } {
  if (dateTo.getTime() < dateFrom.getTime()) {
    return dateFromWasChanged ? { dateFrom: dateFrom, dateTo: dateFrom } : { dateFrom: dateTo, dateTo: dateTo };
  }

  switch (tabId) {
    case "liveview":
    case "insights":
      return { dateFrom, dateTo };
    case "patterns":
      return dateFromWasChanged ? ValidateDatesFromHasChanged(timeAggregation, dateFrom, dateTo) : ValidateDatesToHasChanged(timeAggregation, dateFrom, dateTo);
    default:
      throw new Error(`Invalid tabId: ${tabId}`);
  }
}

function ValidateDatesFromHasChanged(timeAggregationPeriod: TimeAggregationPeriod, dateFrom: Date, dateTo: Date): { dateFrom: Date, dateTo: Date } {
  if (timeAggregationPeriod === TimeAggregationPeriod.Day) {
    const timeDifference = dateTo.getTime() - dateFrom.getTime();

    // Calculate the difference in days
    const dayDifference = timeDifference / (1000 * 3600 * 24);

    // If dateTo is more than 15 days ahead of dateFrom, set dateTo to dateFrom plus 15 days
    if (dayDifference > 15) {
      return { dateFrom, dateTo: new Date(dateFrom.getTime() + 15 * 24 * 60 * 60 * 1000) };
    }
  }
  return { dateFrom, dateTo };
}

function ValidateDatesToHasChanged(timeAggregationPeriod: TimeAggregationPeriod, dateFrom: Date, dateTo: Date): { dateFrom: Date, dateTo: Date } {
  if (timeAggregationPeriod === TimeAggregationPeriod.Day) {
    const timeDifference = dateTo.getTime() - dateFrom.getTime();

    // Calculate the difference in days
    const dayDifference = timeDifference / (1000 * 3600 * 24);

    // If dateTo is more than 15 days ahead of dateFrom, set dateTo to dateFrom plus 15 days
    if (dayDifference > 15) {
      return { dateFrom: new Date(dateTo.getTime() - 15 * 24 * 60 * 60 * 1000), dateTo };
      // new Date(new Date().setUTCDate(dateTo.getUTCDate() - 15))
    }
  }
  return { dateFrom, dateTo };
}