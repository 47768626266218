import React from "react";
import { useKeycloak } from '@react-keycloak/web';
import PilotDropdown from "./PilotDropDown";
import ServiceSwitch from "./ServiceSwitch";
import { SpaceBar } from "@mui/icons-material";

export default function Nav() {
    const { keycloak } = useKeycloak();
    return (
        <div>
            <div className="top-0 w-full flex flex-wrap">
                <section className="x-auto">
                    <nav className="flex justify-between bg-gray-200 text-blue-800 w-screen">
                        <div className="px-5">
                            {!!keycloak.authenticated && keycloak.tokenParsed?.name}

                        </div>
                        <ServiceSwitch />
                        <PilotDropdown />
                        <div className="px-5">
                            {!keycloak.authenticated ? (
                                <button
                                    type="button"
                                    className="text-blue-800"
                                    onClick={() => keycloak.login()}
                                >
                                    Login
                                </button>
                            )
                                :
                                (
                                    <button
                                        type="button"
                                        className="text-blue-800"
                                        onClick={() => keycloak.logout()}
                                    >
                                        Logout
                                    </button>
                                )
                            }

                        </div>
                    </nav>
                </section>
            </div>
        </div >
    );
}