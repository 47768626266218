import { colors, Divider } from "@mui/material";
import { usePilotContext } from "../../../pilotContext";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";


export default function HomePage() {
  const { selectedPilotInfo } = usePilotContext();
  return (
    <div className="min-w-[1000px] overflow-x-auto">
      <div className="flex my-2">
        <div className="min-w-64 ml-5 mb-5 text-left">
          <div className="font-bold" style={{ color: colors.lightGreen[900] }}>EFIM Web Service​</div>
          <div>{selectedPilotInfo.name} in {selectedPilotInfo.street}, {selectedPilotInfo.number}</div>
          <div>{selectedPilotInfo.city}, {selectedPilotInfo.country}</div>
        </div>
      </div>
      <Divider
        variant="middle"
        style={{
          borderBottomColor: colors.lightGreen[300],
          borderBottomWidth: 4,
        }}
      />
      <div className="flex justify-evenly mt-5 px-10">
        {["Total", "HVAC", "Devices"].map((title, index) => (
          <div
            key={index}
            className="flex flex-col border"
            style={{
              borderColor: colors.lightGreen[500],
              width: "240px",
              height: "560px", // 3 times the width
            }}
          >
            <div className="font-bold mt-4 mb-8">{title}</div>
            <Divider
              variant="middle"
              style={{
                borderBottomColor: colors.grey[700],
                borderBottomWidth: 2,
                marginLeft: 25,
                marginRight: 25
              }}
            />
            <div className="flex justify-center px-4 mt-6">
              <Gauge
                width={150}
                height={150}
                value={500}
                valueMax={1}
                text={({ value }) => `500\nkwh`}
                sx={(theme) => ({
                  [`& .${gaugeClasses.valueText}`]: {
                    fontWeight: "bold",
                  },
                  [`& .${gaugeClasses.valueArc}`]: {
                    fill: colors.green[300],
                  },
                })}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}