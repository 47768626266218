import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';
import { DefaultPilotInfo, PilotInfo } from './Common/pilotInfo';

let selectedPilotGlobal: number | null = null; // Module-level variable for global access

interface PilotContextType {
    pilots: number[];
    setPilots: (pilots: number[]) => void;
    selectedPilot: number | null;
    setSelectedPilot: (pilot: number | null) => void;
    pilotInfos: PilotInfo[];
    selectedPilotInfo: PilotInfo;
    selectedService: number;
    setSelectedService: (service: number) => void;
}

const PilotContext = createContext<PilotContextType | undefined>(undefined);

export const PilotProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [pilots, setPilotsState] = useState<number[]>([]);
    const [selectedPilot, setSelectedPilotState] = useState<number | null>(null);
    const [selectedPilotInfo, setSelectedPilotInfoState] = useState<PilotInfo>(DefaultPilotInfo(0));
    const [pilotInfos, setPilotInfos] = useState<PilotInfo[]>([]);
    const [selectedService, setSelectedService] = useState<number>(4);

    useEffect(() => {
        if (selectedPilot) {
            const selectedPilotInfo = pilotInfos.find((pilotInfo) => pilotInfo.id === selectedPilot);
            if (selectedPilotInfo) {
                setSelectedPilotInfoState(selectedPilotInfo);
            }
        }
        else {
            setSelectedPilotInfoState(DefaultPilotInfo(selectedPilot ?? 0));
        }
    }, [selectedPilot, pilotInfos]);

    const setSelectedPilot = useCallback((pilot: number | null) => {
        selectedPilotGlobal = pilot;
        setSelectedPilotState(pilot);
    }, []);

    const setPilots = useCallback((pilots: number[]) => {
        setPilotsState(pilots);
        setSelectedPilot(pilots[0]);
    }, [setSelectedPilot]);

    useEffect(() => {
        const fetchPilotInfos = async () => {
            try {
                const response = await fetch('/api/pilots');
                const data: PilotInfo[] = await response.json();
                setPilotInfos(data);
            } catch (error) {
                console.error('Error fetching pilot infos:', error);
            }
        };

        fetchPilotInfos();
    }, []);

    return (
        <PilotContext.Provider value={{ pilots, setPilots, selectedPilot, setSelectedPilot, pilotInfos, selectedPilotInfo, selectedService, setSelectedService }}>
            {children}
        </PilotContext.Provider>
    );
};

export const usePilotContext = (): PilotContextType => {
    const context = useContext(PilotContext);
    if (!context) {
        throw new Error("usePilotContext must be used within a PilotProvider");
    }
    return context;
};


export const getSelectedPilot = (): number | null => selectedPilotGlobal;