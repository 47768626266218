import { useNavigate } from 'react-router-dom';
import { usePilotContext } from '../pilotContext';

function PilotDropdown() {
    const { pilots, selectedPilot, setSelectedPilot, pilotInfos } = usePilotContext();
    const navigate = useNavigate();


    return (
        pilots.length > 1
            ? <select value={selectedPilot || ''} onChange={event => { navigate("/"); setSelectedPilot(parseInt(event.target.value)) }}>
                {pilots.map((pilot) => (
                    <option key={pilot} value={pilot}>
                        Pilot {pilot} - {pilotInfos.find((pilotInfo) => pilotInfo.id === pilot)?.name}
                    </option>
                ))}
            </select>
            : pilots.length == 1
                ? <p>Pilot {selectedPilot}</p>
                : <p></p>
    );
};

export default PilotDropdown;