export interface PilotInfo{
    id: number;
    name: string;
    country: string;
    city: string;
    street: string;
    number: string;
}

export function DefaultPilotInfo(pilotId: number): PilotInfo
{
    return {
        id: 0,
        name: "",
        country: "",
        city: "",
        street: "",
        number: ""
    };
}